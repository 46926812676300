// == Import Style
import './connexion.scss';
// == Component
function Connexion() {
  return (
    <div className="connexion">
      Connexion
    </div>
  );
}

export default Connexion;
